.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}
.toggleSwitch input[type="checkbox"] {
  display: none;
}

.toggleSwitch .switch {
  position: absolute;
  cursor: pointer;
  background-color: rgba(57, 28, 116, 0.233);
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggleSwitch .switch::before {
  position: absolute;
  content: "";
  left: 3px;
  top: 2px;
  width: 15px;
  height: 15px;
  background-color: rgb(193 131 159 / 35%);
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggleSwitch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(20px);
  /* background-color: rgb(193 131 159 / 100%); */
}
.toggleSwitch input[type="checkbox"]:checked + .switch {
  /* background-color: rgba(57, 28, 116, 0.7); */
}
