.transparentBG {
  width: 99vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 300px;
  height: 325px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 10px;
  /* box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04); */
  box-shadow: 1px 1px 3px rgba(193, 131, 159, 0.65);
  border: 1px solid rgba(193, 131, 159, 0.15);
}

.modalHeader {
  height: 35px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: rgba(57, 28, 116, 0.65);
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalToggleContainer {
  padding: 10px;
  height: 25px;
  align-items: center;
  background-color: rgb(241, 241, 241);
  color: rgba(57, 28, 116, 0.75);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.modalColSelect {
  width: 50%;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(57, 28, 116, 0.75);
  text-align: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: rgba(193, 131, 159, 0.75);
  background: transparent;
  transition: all 0.25s ease;
  position: absolute;
  right: 3px;
  top: 2px;
  align-self: flex-end;
  /* margin-top: -3px;
  margin-right: -3px; */
}

.closeBtn:hover {
  font-weight: 700;
  transform: translateY(-0.05rem) scale(1.1);
  transition: all 0.45s ease;
  color: #5e3a9d;
}
