.wrapper {
  margin-top: 50px;
  /* margin-right: 75px; */
  /* text-align: left; */
  min-width: 250px;
}
.userListContainer {
  /* box-shadow: 1px 0px 5px rgba(135, 107, 190, 0.75); */
  border: solid 1px rgba(193, 131, 159, 0.15);
  border-radius: 6px;
  min-height: 350px;
  max-height: 650px;
  min-width: 250px;
  margin: 0 0px;
  padding: 5px 5px;
  text-align: left;
  overflow-x: auto;
}
.userListContainer::-webkit-scrollbar {
  display: none;
}

.listTitle {
  font-weight: 600;
  margin: 5px 10px;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
  padding: 5px 0px;
  transition: all 0.45s ease;
}

.cardBody {
  background-image: linear-gradient(
    to bottom right,
    rgba(193, 131, 159, 0.15),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75)
  );
  width: 85%;
  margin: 3px 15px;
  padding: 0.15rem 0.65rem;
  /* border: solid 1px rgba(0, 0, 0, 0.18); */
  border: solid 1px rgba(193, 131, 159, 0.45);
  /* cursor: pointer; */
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  box-shadow: 1px 1px 1px rgba(135, 107, 190, 0.55);
  /* box-shadow: 1px 2px 2px rgba(77, 107, 118, 0.85); */
}

.cardTitle {
  font-weight: 600;
  margin: 3px 0;
}

.cardText {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
  margin: 2px 0;
}

.cardDelete {
  display: flex;
  align-items: center;
}

.deleteBtn {
  background-color: transparent;
  align-items: center;
  border: none;
  margin-top: 4px;
  font-size: 20px;
  cursor: pointer;
  color: rgba(193, 131, 159, 0.75);
  transition: all 0.45s ease;
}

.deleteBtn:hover {
  font-weight: 700;
  transform: translateY(-0.05rem) scale(1.1);

  color: #5e3a9d;
}

.btnContainer {
  margin-top: 10px;
  text-align: right;
}

.btnBack {
  border-radius: 5px;
  background-color: #5e3a9d;
  color: #fff;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  border: none;
}

.btnBack:hover {
  transform: translateY(-0.02rem) scale(1.03);
  /* color: rgba(193, 131, 159, 1); */
  transition: all 0.5s ease;
}
