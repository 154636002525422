.tableContainer {
  width: 95%;
  max-width: 2000px;
  margin: 15px auto;
}

.filterWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.searchContainer {
  top: 60px;
  /* width: 25%; */
  height: 40px;
  margin-left: 119px;
  padding: 10px;
  display: flex;
  /* justify-content: center; */
  position: relative;
  color: rgba(57, 28, 116, 0.85);
  font-size: 24px;
  font-weight: 600;
}

.toggleContainer {
  top: 64px;
  width: 210px;
  /* height: 30px; */
  margin-right: 170px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: rgba(57, 28, 116, 0.85);
}

.toggleTitle {
  color: rgba(57, 28, 116, 0.85);
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 600;
  /* text-decoration: underline; */
  border-bottom: 1px solid rgba(57, 28, 116, 0.85);
  transition: all 0.25s ease;
}

.toggleTitle_disabled {
  color: rgba(57, 28, 116, 0.4);
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.25s ease;
}

/* ***********  Toolbar  ************ */

.toolbarContainer {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
  padding: 15px 5px;
  position: sticky;
  min-width: 40%;
  max-width: 45%;
  height: 30px;
  top: 40px;
  box-shadow: 1px 1px 3px rgba(193, 131, 159, 0.65);
  /* box-shadow: 1px 2px 2px rgba(77, 107, 118, 0.85); */
  border: 1px solid rgba(193, 131, 159, 0.15);
}

.linkDiv {
  padding: 3px 10px;
  margin: 0 0px;
  display: flex;
  align-items: center;
}

.toolbarBtn {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  padding: 0;
  padding-bottom: 2px;
  margin-bottom: 0;
  background-color: white;
  outline: none;
  border: none;
  text-decoration: none;
  color: rgba(57, 28, 116, 0.85);
  /* color: rgba(77, 107, 118, 1); */
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.toolbarBtn:hover {
  border-bottom: 1px solid rgba(57, 28, 116, 0.85);
}

.toolbarLink {
  text-decoration: none;
  color: rgba(57, 28, 116, 0.85);
  /* color: rgba(77, 107, 118, 1); */
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.toolbarLink_disabled {
  text-decoration: none;
  color: rgba(57, 28, 116, 0.35);
  /* color: rgba(77, 107, 118, 0.35); */
  font-size: 16px;
  font-weight: 600;
  pointer-events: none;
}

.toolbarIcon {
  font-size: 22px;
  vertical-align: bottom;
}

.toolbarLink:hover {
  border-bottom: 1px solid rgba(57, 28, 116, 0.85);
}

/* ***********  Table ************ */

.sortIconSpan {
  align-items: center;
}

.sortIcon {
  margin-left: 10px;
  margin-top: 5px;
  align-items: center;
  font-size: 20px;
  color: rgb(218, 87, 146);
}

.defaultSortIcon {
  margin-left: 10px;
  margin-top: 5px;
  align-items: center;
  font-size: 19px;
}

.userTable {
  margin: 0 auto;
  width: 100%;
  box-shadow: 1px 1px 10px 1px rgba(193, 131, 159, 0.55);
  /* box-shadow: 4px 2px 6px rgba(77, 107, 118, 0.6); */
  border-spacing: 0;
  border-collapse: collapse;
  text-align: left;
  /* border: 1px solid rgba(193, 131, 159, 0.15); */
  /* border: 1px solid rgba(77, 107, 118, 0.85); */
}

.userTable thead tr {
  background-color: rgba(57, 28, 116, 0.4);
  background-color: rgba(57, 28, 116, 1);
  /* border-bottom: 1px solid rgba(193, 131, 159, 0.85); */
  color: #fff;
}

.userTable thead tr:last-of-type {
  border-bottom: 1px solid rgba(193, 131, 159, 0.85);
}

.userTable td {
  padding: 6px 10px;
  cursor: pointer;
}

.userTable th {
  padding: 8px 11px;
  font-weight: 500;
  font-size: 16px;
  /* border-top: 2px solid #ddd; */
}
.userTable td:last-child {
  border: none;
}

.userTable th:last-child {
  border-right: none;
}

.userTable th,
td {
  min-width: 25px;
  max-width: 200px;
  border-right: 2px solid rgba(255, 255, 255, 0.514);
}

.userTable th:first-child {
  border: none;
}
.userTable td:first-child {
  border: none;
}

.userTable tbody tr {
  background-color: #f3f3f3;
  border-bottom: 1px solid rgba(193, 131, 159, 0.85);
  /* border-bottom: 1px solid rgba(77, 107, 118, 0.623); */
}
.userTable tbody tr:nth-child(even) {
  background-color: rgba(172, 159, 187, 0.35);
}

.userTable tbody tr:last-of-type {
  border-bottom: 2px solid rgba(193, 131, 159, 0.85);
  /* border-bottom: 2px solid rgba(77, 107, 118, 0.85); */
}

.userTable tbody tr:hover {
  transition: all 0.2s ease;
  /* background-color: rgba(223, 243, 227, 0.75); */
  background-color: rgba(193, 131, 159, 0.35);
}
