.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid rgba(57, 28, 116, 0.65);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 0.5s ease-in infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
