.globalFilterInput {
  display: inline-block;
  font-size: 1.05rem;
  padding: 8px;
  margin: 0;
  margin-left: 10px;
  border: 0;
  box-shadow: 1px 1px 3px rgb(193 131 159 / 99%);
  border-radius: 4px;
}

.globalFilterInput:focus {
  outline: none;
  border-bottom: 2px solid rgb(193 131 159 / 100%);
  transition: all 0.05s ease;
}

.columnFilterInput {
  border-radius: 5px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.85);
  /* box-shadow: -3px 0px 1px 1px rgb(193 131 159 / 75%); */
  border: 1px solid rgb(193 131 159 / 75%);
  display: inline-block;
  margin: 0;
  padding: 4px;
}

.columnFilterInput:focus {
  background-color: rgb(255, 255, 255);
  /* box-shadow: -3px 0px 1px 2px rgb(193 131 159 / 100%); */
  border: 1px solid rgb(193 131 159 / 100%);
  outline: none;
  /* transition: all 0.05s ease; */
}

.columnFilterSelect {
  /* box-shadow: -3px 0px 1px 1px rgb(193 131 159 / 75%); */
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 4px;
  border: 1px solid rgb(193 131 159 / 75%);
  padding: 3px;
  cursor: pointer;
}

.columnFilterSelect:focus {
  outline: none;
  /* box-shadow: -3px 0px 1px 1px rgb(193 131 159 / 100%); */
  border: 1px solid rgb(193 131 159 / 100%);
  background-color: rgb(255, 255, 255);
  /* transition: all 0.05s ease; */
}
