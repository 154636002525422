nav {
  font-size: 16px;
  position: relative;
  top: 0;
  height: 65px;
  background-color: rgba(57, 28, 116, 1);
  box-shadow: 0px 3px 5px rgba(57, 28, 116, 0.65);
  display: flex;
  justify-content: center;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  margin: 0 auto;
  padding: 0 50px;
  max-width: 2000px;
  width: 100%;
}
.logoImg {
  height: 36px;
  width: auto;
  margin-right: 35px;
  margin-top: 2px;
}
.navLogo {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 400;
  transition: all 0.5s ease;
}

.navLogo a {
  text-decoration: none;
}

.navLogo:hover {
  transform: scale(1.02);
}

.loginButton {
  border-radius: 5px;
  background-color: #5e3a9d;
  color: #fff;
  padding: 8px 15px;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
}

.loginButton:hover {
  background-color: #fff;
  transform: translateY(-0.1rem) scale(1.05);
  color: #5e3a9d;
}
