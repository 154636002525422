.mainContainer {
  display: flex;
  flex-direction: row;
  height: 450px;
  justify-content: center;
  align-items: center;
}

.sidebarContainer {
  height: 100%;
  width: auto;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 60px;
  left: 0;
  padding: 0 5px;
  box-shadow: 1px 0px 3px rgba(135, 107, 190, 0.2);
}

.formContainer {
  width: 65%;
  margin: 0 auto;
}
