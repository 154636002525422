.formWrapper {
  height: 100%;
  margin-top: 65px;
}

.formContainer {
  height: 300px;
  /* padding: 10px; */
  margin-left: 61px;
  box-shadow: 1px 1px 5px rgba(135, 107, 190, 0.45);
  /* border: solid 1px rgba(193, 131, 159, 0.15); */
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: space-around;
}
.formContainer form {
  display: flex;
  flex-direction: row;
  text-align: left;
  height: 75%;
}
.formCol {
  width: 20%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.inputDiv {
  height: 60px;
}

/* .formCol label,
input {
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding: 4px;
} */

.formCol input {
  width: 210px;
  margin-top: 3px;
  padding: 8px;
  border: 1px solid rgba(193, 131, 159, 0.75);
  /* border: 1px solid #5e3a9d; */
  border-radius: 4px;
}

.formCol input::placeholder {
  color: rgba(46, 46, 46, 0.5);
  font-size: 15px;
  font-weight: 500;
}

.formCol label {
  margin-bottom: 2px;
  margin-left: 2px;
  padding: 0;
  font-weight: 500;
  color: rgba(135, 107, 190, 1);
}

.btnContainer {
  text-align: right;
  margin-top: 10px;
  margin: 15px auto;
  display: flex;
  width: 75%;
  justify-content: space-evenly;
}

.submitBtn {
  border-radius: 5px;
  background-color: #5e3a9d;
  color: #fff;
  padding: 7px 10px;
  margin: 0 20px;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
}

.submitBtn:hover {
  transform: translateY(-0.02rem) scale(1.03);
  /* color: rgba(193, 131, 159, 1); */
  transition: all 0.5s ease;
}

.btnBack {
  border-radius: 5px;
  background-color: #5e3a9d;
  color: #fff;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
}

.btnBack:hover {
  transform: translateY(-0.02rem) scale(1.03);
  /* color: rgba(193, 131, 159, 1); */
  transition: all 0.5s ease;
}

.formCol input:focus {
  /* box-shadow: 1px 1px 4px rgba(94, 58, 157, 0.75); */
  /* box-shadow: 1px 1px 4px rgba(135, 107, 190, 0.75); */
  outline: none;
  border-bottom: 2px solid rgba(135, 107, 190, 0.85);
  /* border-bottom: 2px solid #5e3a9d; */
  transition: all 0.12s ease;
}
