.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  padding-top: 3px;
}

.checkbox > span {
  color: #34495e;
  padding: 0.5rem 0.25rem;
}

.checkbox > input {
  height: 17px;
  width: 17px;
  appearance: none;
  border: 1px solid rgb(165, 165, 165);
  border-radius: 4px;
  outline: none;
  /* transition-duration: 0.1s; */
  background-color: #fff;
  cursor: pointer;
}

/* .checkbox > input:hover {
  background-color: rgba(57, 28, 116, 0.35);
} */
.checkbox > input:checked {
  border: 1px solid #bbb;
  background-color: #5e3a92;
}

.checkbox > input:checked + span::before {
  content: '\2714';
  display: block;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 0.42rem;
  top: 0.27rem;
}
